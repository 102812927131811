import PropTypes from "prop-types";
import React from 'react';

const Banner = ({image, image2}) => {
    return(
        <div className="chartbanner">
            <img className="logo-light" src={process.env.PUBLIC_URL + image} alt="Autoview - Webhooks > Broker API - Light theme" />
            <img className="logo-dark" src={process.env.PUBLIC_URL + image2} alt="Autoview - Webhook > Broker API - Dark theme" />
        </div>
    )
}
Banner.propTypes = {
    image: PropTypes.string
};

export default Banner;
