import React from 'react';

import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import Separator from "../../elements/separator/Separator";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";





const footerIntemFive =  footerOne[5];
// const footerIntemTwo =  footerOne[2];

const disclosureRisk = footerOne[6];
const disclosureHypothetical = footerOne[7];
const disclosureTestimonial = footerOne[8];




const FooterTwo = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionSeven />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemFive.title}</h4>
                                    <div className="inner">
                                        <h6 className="subtitle">{footerIntemFive.subtitle}</h6>
                                    </div>
                                </div>
                            </div>
                             {/* Social Links WIP */}
                            {/* <div className="col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemTwo.title}</h4>
                                    <div className="inner">
                                        <h6 className="subtitle">{footerIntemTwo.text}</h6>
                                        <p className="small text-muted">{footerIntemTwo.url}</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* End Single Widget  */}
                        </div>


                        <Separator />

                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="inner">
                                        <h6 className="subtitle">{disclosureRisk.title}</h6>
                                        <p className="text-muted">{disclosureRisk.content}</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            <div className="col-md-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="inner">
                                        <h6 className="subtitle">{disclosureTestimonial.title}</h6>
                                        <p className="text-muted">{disclosureTestimonial.content}</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            <div className="col-12">
                                <div className="rn-footer-widget">
                                    <div className="inner">
                                        <h6 className="subtitle">{disclosureHypothetical.title}</h6>
                                        <p className="text-muted">{disclosureHypothetical.content}</p>
                                    </div>
                                </div>
                            </div>
                            <Separator/>
                            <br></br>

                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
