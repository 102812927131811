import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {FiArrowRight, FiBookOpen, FiMessageSquare, FiTwitter, FiYoutube} from 'react-icons/fi';
import Logo from "../logo/Logo";

const CalltoActionSeven = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-7">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8 col-md-8">
                        <div className="inner">
                            <div className="content text-start">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/Header_White.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/Header_Grey.png`}
                                />
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p className="subtitle">Autoview | Automate your trades</p>
                                </ScrollAnimation>
                                <div className="row mt--30">
                                <div className="col-lg-12">
                                    <ul className="social-icon social-default with-gradient">
                                        <li><a href="https://youtube.com/c/AutoviewExtension" target="_blank" rel="noreferrer"><FiYoutube/></a></li>
                                        <li><a href="https://twitter.com/AutoviewExt" target="_blank" rel="noreferrer"><FiTwitter /></a></li>
                                        <li><a href="https://discord.gg/autoview" target="_blank" rel="noreferrer"><FiMessageSquare /></a></li>
                                        <li><a href="https://use.autoview.com/hc/en-us" target="_blank" rel="noreferrer"><FiBookOpen /></a></li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="call-to-btn text-start mt_sm--20 text-md-end">
                                <a className="btn-default btn-icon" href="/platforms/">Get Autoview <i className="icon"><FiArrowRight/></i></a>
                            </div>
                        </ScrollAnimation>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default CalltoActionSeven;
