import React from 'react';
import {FiArrowRight, FiChevronRight} from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from '../elements/counterup/CounterUpFour';
import Banner from '../elements/gallery/headerimg';


const HomePage = () => {

    return (
        <>
            <main className="page-wrapper ">

                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent"/>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-750 mainpad">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient">Turn on Autopilot</h1>
                                    <p className="subtitle">
                                        Automate your trades. Automate your life.</p>

                                    <div className="button-group mt--40">
                                        <a className="btn-default btn-medium round btn-icon" href="/platforms/">
                                            Get Autoview <i className="icon"><FiArrowRight/></i></a>
                                        <a className="btn-default btn-medium btn-border round btn-icon"
                                           href="https://discord.gg/autoview" target="_blank" rel="noreferrer">
                                            Join Our Community <i className="icon"><FiArrowRight/></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-lg-block col-lg-5 col-xl-6 order-1 order-lg-2">
                                <div className="frame-image">
                                    <a href="/platforms/">
                                        <Banner
                                            image={`${process.env.PUBLIC_URL}/images/banner/cloud-dark-orange.png`}
                                            image2={`${process.env.PUBLIC_URL}/images/banner/cloud-light-orange.png`}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* End Slider Area  */}

                <Separator/>

                {/* Start Service Area  */}
                <div id="about" className="service-area rn-section-gap mainpad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="About Us"
                                    title="What is Autoview?"
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <ul className="list-icon">
                                    <li><span className="icon"><FiChevronRight/></span>Automated Trading</li>
                                    <li><span className="icon"><FiChevronRight/></span>No Experience Required</li>
                                    <li><span className="icon"><FiChevronRight/></span>No Minimum to Start</li>
                                    <li><span className="icon"><FiChevronRight/></span>Integrated with Multiple
                                        Exchanges
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Earning income by clocking in does not need to be your forever.
                                    Make trades around the clock without lifting a finger. Autoview is a website
                                    application
                                    that places the trades you want, when you want them. Whether you want to
                                    set it and forget it or dive into the world of custom scripts, Autoview listens to
                                    your alert signals so all you have to do is sit back and enjoy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator/>

                {/* Start Service Area  */}
                <div className="service-area rn-section-gap mainpad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Trade with Autoview"
                                    title="So you never miss an opportunity to earn"
                                    description=""
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle="service__style--1 icon-circle-style"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator/>

                <div className="service-area rn-section-gap rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Supported Exchanges"
                                />
                            </div>
                        </div>

                        <div className="container-all text-center">
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://alpaca.markets/" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/alpaca.png" alt="Alpaca" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#f3ba2f" }}>
                                <a href="https://accounts.binance.com/register?ref=60312731" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/binance.png" alt="Binance" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#333" }}>
                                <a href="https://www.binance.us/?ref=35047407" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/binance.us.png" alt="Binance US" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://www.bitfinex.com/sign-up?refcode=dk0KqHaAH" target="_blank"
                                   rel="noreferrer">
                                    <img src="./images/exchanges/bitfinex.png" alt="Bitfinex" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#333" }}>
                                <a href="https://partner.bitget.com/bg/RJ241W" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/bitget.png" alt="Bitget" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://www.bitmex.com/register/vrkVRt" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/bitmex.png" alt="BitMEX" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://partner.bybit.com/b/autoview" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/bybit.png" alt="ByBit" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://capital.com/" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/capital.com.png" alt="Capital.com" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#004ef1" }}>
                                <a href="https://pro.coinbase.com" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/coinbase.png" alt="Coinbase" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#061121" }}>
                                <a href="https://crypto.com/exch/gzw5vukrv2" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/crypto.com.png" alt="Crypto.com" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://www.deribit.com/reg-3466.8566?q=home" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/deribit.png" alt="Deribit" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#2250da" }}>
                                <a href="https://www.gate.io/signup/12338967" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/gate.io.png" alt="Gate.io" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://www.gemini.com" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/gemini.png" alt="Gemini" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://www.htx.com/" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/htx.png" alt="HTX" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#5741d9" }}>
                                <a href="https://www.kraken.com" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/kraken.png" alt="Kraken" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#2bb38e" }}>
                                <a href="https://www.kucoin.com/" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/kucoin.png" alt="KuCoin" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#fff" }}>
                                <a href="https://www.oanda.com/" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/oanda.png" alt="OANDA" className="imggallery"/>
                                </a>
                            </div>
                            <div className="container3" style={{ backgroundColor: "#333" }}>
                                <a href="https://www.okx.com/" target="_blank" rel="noreferrer">
                                    <img src="./images/exchanges/okx.png" alt="OKX" className="imggallery"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator/>

                <Separator/>

                {/* Start Counter Area  */}
                <div className="rwt-counterup-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Massive Trading Volume"
                                    title="Autoview Stats"
                                    description=""
                                />
                            </div>
                        </div>
                        <CounterUpFour column="col-lg-3 col-md-6 col-12" counterStyle="counter-style-4"
                                       textALign="text-center"/>
                    </div>
                </div>

                <Separator/>

                <FooterTwo/>

                <Copyright/>

            </main>
        </>
    )
}
export default HomePage;
